if(Alpine){
    function dropdownMenu() {
        return {
            selected: null,
            isTouchDevice: 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0,
            isDesktop: window.innerWidth > 1024,
            handleAnchorClick(event, itemID) {
                if (this.isTouchDevice) {
                    if (this.selected !== itemID) {
                        event.preventDefault();
                        this.selected = itemID;
                    }
                }
            }
        };
    }

  Alpine.data('dropdownMenu', dropdownMenu);
}